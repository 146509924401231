import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/postList"
import DataTable from "../components/dataTable"
import PlatformHeading from "../components/PlatformHeading"

const PlatformDetails = ({data}) => {
  const post = data.wpPlatform;
  return(
  <Layout HeadClass="header-inner">
    <Seo title={post.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={post.seo.metaDesc} focuskw={post.seo.focuskw} />
    <div className="case-study px-md-4 text-white">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-11 col-lg-8 col-xl-8 col-xxl-9" data-aos="fade-up" data-aos-delay="200">
            <div className="subtitle text-uppercase h5">SYSTEMS & PLATFORMS</div>
            <h1 className="title pb-1" dangerouslySetInnerHTML={{__html: post.title}} />
            <div dangerouslySetInnerHTML={{__html: post.content}} />
            <p></p>
            <p><small>[Credit : {post.platform.credit}]</small></p>
          </div>
          
        </div>
        
      </div>
      
    </div>

    <div className="py-120">
      <div className="container">
        <div className="row mb-lg-5 mb-4">
          <div className="col-sm-11 col-md-9 col-lg-7 col-xxl-8">
              <h2 className="h1" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: post.platform.customerBenefitsTitle}} />
              <p  data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{__html: post.platform.customerBenefitsSubtitle}} />
          </div>
        </div>

        <div className="row align-items-center justify-content-between">
          {post.platform.customerBenefitsList && post.platform.customerBenefitsList.map((item, index) => (
            <div className="col-sm-6 col-lg-5 benifit-item mb-lg-5 mb-4 d-flex align-items-center" data-aos="fade-up" key={index}>
              <span className="me-xl-3 me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi d-block bi-check-square-fill" viewBox="0 0 16 16">
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                </svg>
              </span>
              <p className="m-0" dangerouslySetInnerHTML={{__html: item.customerBenefits}} />
            </div>
          ))}          
        </div>
      </div>
    </div>


    <div className="py-120 overflow-hidden pt-md-5 pt-4">
      <div className="container">
        <div className="row text-center justify-content-center text-center mb-md-5 mb-4 pb-md-4">
          <div className="col-sm-12 col-md-11 col-lg-8 col-xxl-8">
              <PlatformHeading />
          </div>
        </div>
      </div>

      <div className="data-table-outer">
        <div className="container">
          <DataTable  titles={post.platform.rowTitleOfTable} posts={post.platform.dataOfTable[0]} />
        </div>
      </div>
    </div>
    

    {post.platform.discoverMoreAboutBlogs?.length > 0 && (
      <div className="py-120 mb-5 pt-5">
          <div className="container">
              <div className="row align-items-center justify-content-center justify-content-xl-between">
                  <div className="d-flex col-sm-10 col-xl-4 m-auto">
                      <div className="m-xl-auto w-100 mb-xl-0 mb-md-5 mb-4 d-flex flex-xl-wrap justify-content-xl-start justify-content-between">
                        {/*  data-aos="fade-up" data-aos-delay="0.2s" */}
                      <h2 className="mb-xl-3 h1 w-100 mb-0">Discover <em>more <br />about</em> <span  dangerouslySetInnerHTML={{__html: post.title}}/></h2>
                      <Link aria-label="View All Blog" to="/blog" className="btn text-uppercase btn-link d-inline-flex align-items-center">
                          ViEW ALL

                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                          </svg>
                      </Link>
                      </div>
                  </div>
                  <div className="col-xl-6 col-sm-10">
                      <PostList posts={post.platform.discoverMoreAboutBlogs} />
                  </div>
              </div>
          </div>
      </div>
    )}
    
  
  </Layout>
  )
}

export default PlatformDetails

export const query = graphql `
  query($slug: String) {
    wpPlatform(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
      title
      content
      platform {
        credit
        customerBenefitsTitle
        customerBenefitsSubtitle
        customerBenefitsList {
          customerBenefits
        }
        rowTitleOfTable {
          title
        }
        dataOfTable {
          logoData {
            logo {
              altText
              mediaItemUrl
            }
            dataOfColumns {
              data
            }
          }
        }
        discoverMoreAboutBlogs {
          ... on WpBlog {
            slug
            title
            featuredImage {
              node {
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

