import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

export default function DataTable({titles, posts}) {
  return (
    <div className="data-table d-flex align-items-start">
        <div className="data-table-list">
        <div className="data-table-list-head"></div>
        {titles && titles.map((text, index) => (
            <div className="data-table-list-item" key={index} dangerouslySetInnerHTML={{__html: text.title}} />
        ))}
        </div>
        
        {posts.logoData && 
            <div className="data-table-list-responisve">
                <Swiper
                    slidesPerView={"auto"}
                    grabCursor={true}
                    className="swipe-data-tables"
                    spaceBetween={20}
                >
                    {posts.logoData && posts.logoData.map((platform, index) => (
                        <SwiperSlide key={index * 99}>
                            <div className="data-table-list bg-grad">
                                <div className="data-table-list-head">
                                    <img loading="lazy" src={platform.logo && platform.logo.mediaItemUrl} alt={platform.logo && platform.logo.altText} width="273" height="90" className="img-fluid gatsby-image-wrapper" />
                                </div>
                                {platform.dataOfColumns && platform.dataOfColumns.map((col, index) => (
                                    <div className="data-table-list-item" key={index * 199} dangerouslySetInnerHTML={{__html: col.data}} />
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}                
                </Swiper>
            </div>
        }
    </div>
  )
}
