import React from 'react'
import { graphql, StaticQuery } from "gatsby"

const PlatformHeading = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "platform"}}) {
            nodes {
              platformPage {
                platformsSoftwaresTitle
                platformsSoftwaresSubtitle
              }
            }
          }
      }
    `}
    render={data => {
      const pageContent = data.allWpPage.nodes[0];
      return (
    <>
        <h2 className="h1" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.platformPage.platformsSoftwaresTitle}} />
        <p  data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{__html: pageContent?.platformPage.platformsSoftwaresSubtitle}} />
    </>
 );
}}
></StaticQuery>
);

export default PlatformHeading;
